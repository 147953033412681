import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { localStorageKeys } from "../data";
import { useCheckLoginTokenMutation, useLoginMutation } from "../queries";
import {
  type ICheckLoginTokenUserPostReq,
  type ILoginUserPostReq,
} from "../requests/types";

const useAuth = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const loginMutation = useLoginMutation();
  const checkLoginTokenMutation = useCheckLoginTokenMutation();

  const handleLogin = (data: ILoginUserPostReq) => {
    console.log(data);
    loginMutation.mutate(data, {
      onSuccess({ response }) {
        if (typeof response === "undefined") return;

        localStorage.setItem(localStorageKeys.apiAuthorization, response.token);

        navigate("/");
      },
    });
  };

  const handleCheckLoginToken = (data: ICheckLoginTokenUserPostReq) => {
    console.log(data);
    checkLoginTokenMutation.mutate(data, {
      onSuccess({ response }) {
        if (typeof response === "undefined") return;

        localStorage.setItem(localStorageKeys.apiAuthorization, response.token);

        navigate("/");
      },
      onError(response: any) {
        if (response?.response?.status === 403) {
          toast("توکن نامعتبر است.", {
            type: "error",
          });
          setTimeout(() => {
            navigate("/login");
            return;
          }, 2000);
        }
      },
    });
  };

  const handleLogout = () => {
    localStorage.removeItem(localStorageKeys.apiAuthorization);

    queryClient.clear();

    navigate("/login");

    toast("شما از حساب کاربری خود خارج شدید", {
      type: "info",
      toastId: "logout",
    });
  };

  return {
    handleLogin,
    handleLogout,
    handleCheckLoginToken,
    loginMutation,
  };
};

export default useAuth;
