import { useLocation, useNavigate } from "react-router-dom";

import { MenuItem } from "../../components";
import { useGlobalContext } from "../../context";
import { useSettingUsersQuery, useUserQuery } from "../../queries";
import { routeNames } from "../../router";
import styles from "./styles.module.scss";

const Menu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isShowMenubar, handleHideMenubar } = useGlobalContext();
  const userQuery = useUserQuery();
  const settingsUserQuery = useSettingUsersQuery({ offset: 0, limit: 10000 });

  const findUser = settingsUserQuery?.data?.response?.users?.find(
    (u) => u.id === userQuery?.data?.user?.id
  );

  return (
    <div>
      <div
        className={`${styles.wrapper} ${
          isShowMenubar ? styles.wrapperShow : ""
        }`}
      >
        <MenuItem
          type="item"
          count={userQuery?.data?.items_count}
          isActive={
            location.pathname === routeNames.dashboard ||
            location.pathname.match(/^\/details-item/) !== null
          }
          onClick={() => {
            handleHideMenubar();

            navigate(routeNames.dashboard);
          }}
        />

        <MenuItem
          type="announcement"
          count={userQuery?.data?.notifications_count}
          isActive={location.pathname.match(/^\/announcement/) !== null}
          onClick={() => {
            handleHideMenubar();

            navigate(routeNames.announcements);
          }}
        />

        <MenuItem
          type="group"
          isActive={location.pathname.match(/^\/groups/) !== null}
          onClick={() => {
            handleHideMenubar();

            navigate(routeNames.groups);
          }}
        />

        <MenuItem
          type="salary"
          isActive={location.pathname.match(/^\/salaries/) !== null}
          onClick={() => {
            handleHideMenubar();

            navigate(routeNames.salaries);
          }}
        />

        {findUser?.is_admin && (
          <MenuItem
            type="setting"
            isActive={location.pathname.match(/^\/settings/) !== null}
            onClick={() => {
              handleHideMenubar();

              navigate(routeNames.announcementSetting);
            }}
          />
        )}
      </div>

      <div
        onClick={handleHideMenubar}
        className={`${styles.shadow} ${isShowMenubar ? styles.shadowShow : ""}`}
      />
    </div>
  );
};

export default Menu;
